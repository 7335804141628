import Cookies from 'js-cookie';

type RecEvent = {
  contextItemId: string;
  blockId: string;
  item: RecommendationsType;
  position: number;
};

/**
 * Обертка отправки событий для проверки инициализации top100
 * @param callback - колбэк для отработки с top100
 *
 * Тут эта функция больше не используется, но было решено ее оставить.
 */
export const top100Init = (callback: () => void) => {
  if (window.top100Counter) {
    callback();
  } else {
    window.addEventListener('top100-init', () => {
      callback();
    });
  }
};

/**
 * Обертка аккумуляции проверок загрузок скриптов и отправка событий
 * @param callback - колбэк для отработки с сохранением
 */
const safeEvent = (callback: () => void) => {
  if (window.rcm) {
    callback();
  } else {
    window.addEventListener('recommender-init', () => {
      callback();
    });
  }
};

/**
 * Инициализация скрипта счетчиков recommender.js
 * @see https://rcm.rambler.ru/docs/api.html
 * @param adtech - данные для рекоммендательной системы
 * @param userId - кука rccid
 */
export const RCMsetupRecommender = (
  adtech: AdtechType,
  userId: Runtime['userId'],
) =>
  safeEvent(() => {
    window.rcm('setup', {
      rcmId: adtech.rcmID,
      xuid: Cookies.get('ruid'),
      userId,
    });
  });

/**
 * Метрика открытия страницы для рекомендательной системы ATD
 * @param clusterId - id кластера
 */
export const RCMpageView = (clusterId: Cluster['id'] | null) =>
  clusterId &&
  safeEvent(() => window.rcm('pageview', { itemId: `${clusterId}` }));

/**
 * Метрика прочтения страницы для рекомендательной системы ATD
 * @param clusterId - id кластера
 */
export const RCMpageRead = (clusterId: Cluster['id'] | null) =>
  clusterId &&
  safeEvent(() => window.rcm('pageread', { itemId: `${clusterId}` }));

/**
 * Метрика прочтения страницы для рекомендательной системы ATD.
 * https://recsys.pages.rambler-co.ru/recommender/docs/index.html#/transport/events?id=%d0%a0%d0%b0%d0%b7%d0%bc%d0%b5%d1%82%d0%ba%d0%b0-%d1%80%d0%b5%d0%ba%d0%be%d0%bc%d0%b5%d0%bd%d0%b4%d0%b0%d1%82%d0%b5%d0%bb%d1%8c%d0%bd%d0%be%d0%b3%d0%be-%d0%b1%d0%bb%d0%be%d0%ba%d0%b0
 * https://confluence.rambler-co.ru/pages/viewpage.action?pageId=48183873#id-%D0%A0%D0%B5%D0%BA%D0%BE%D0%BC%D0%B5%D0%BD%D0%B4%D0%B0%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D0%B0%D1%8F%D1%81%D0%B8%D1%81%D1%82%D0%B5%D0%BC%D0%B0-%D0%98%D0%BD%D0%B8%D1%86%D0%B8%D0%B0%D1%86%D0%B8%D1%8F%D0%B1%D0%BB%D0%BE%D0%BA%D0%B0
 * @param blockId - ID, используемый для запроса данных от рекомендалки
 * @param clusterId - ID кластера, на котором используется блок
 */
export const RCMblockInit = (blockId: string, clusterId?: Card['id']) =>
  safeEvent(() => {
    const options: {
      blockId: string;
      contextItemId?: string | null;
    } = { blockId, contextItemId: clusterId ? `${clusterId}` : null };

    window.rcm('blockinit', options);
  });

/**
 * Метрика загрузки любой страницы, не являющейся контентом.
 * @param hubId - id страницы (у нас это pageName роутер-конфига)
 */
export const RCMhubPageView = (hubId: string) =>
  safeEvent(() => window.rcm('hubpageview', { hubId }));

const createRecEvent = ({
  blockId,
  contextItemId,
  position,
  item,
}: RecEvent) => ({
  contextItemId,
  blockId,
  item,
  position,
});

/**
 * Метрика прочтения страницы для рекомендательной системы ATD
 * @param props - пропсы
 * @param props.contextItemId - идентификатор места расположения блока (ID кластера или еще что-то)
 * @param props.blockId - ID, используемый для запроса данных от рекомендалки
 * @param props.clusterId - ID кластера, на котором используется блок
 * @param props.rcmID - какой-то ID, приходит вместе с кластером в ответе ручки рекомендательной системы
 * @param props.showID - какой-то ID, приходит вместе с кластером в ответе ручки рекомендательной системы
 * @param props.position - позиция в списке, начинается с 0
 */
export const RCMFullTextShow = (props: RecEvent) =>
  safeEvent(() => window.rcm('fulltextShow', createRecEvent(props)));

/**
 * Метрика показа кластера в блоке с рекомендательной выдачей.
 * @param props - пропсы
 * @param props.blockId - ID, используемый для запроса данных от рекомендалки
 * @param props.contextItemId - идентификатор страницы, на который видим блок (ID страницы кластера или тип страницы)
 * @param props.itemClusterID - ID кластера в этом блоке
 * @param props.rcmID - какой-то ID, приходит вместе с кластером в ответе ручки рекомендательной системы
 * @param props.showID - какой-то ID, приходит вместе с кластером в ответе ручки рекомендательной системы
 * @param props.position - позиция в списке, начинается с 0
 */
export const RCMshow = (props: RecEvent) =>
  safeEvent(() => window.rcm('show', createRecEvent(props)));

/**
 * Метрика клика по кластеру в блоке с рекомендательной выдачей.
 * @param props - пропсы
 * @param props.blockId - ID, используемый для запроса данных от рекомендалки
 * @param props.contextItemId - идентификатор страницы, на который видим блок (ID страницы кластера или тип страницы)
 * @param props.itemClusterID - ID кластера в этом блоке
 * @param props.rcmID - какой-то ID, приходит вместе с кластером в ответе ручки рекомендательной системы
 * @param props.showID - какой-то ID, приходит вместе с кластером в ответе ручки рекомендательной системы
 * @param props.position - позиция в списке, начинается с 0
 */
export const RCMclick = (props: RecEvent) =>
  safeEvent(() => window.rcm('click', createRecEvent(props)));

/**
 * Cмена userId для rcm. Нужен например при логине/логауте, когда userId меняется без перезагрузки страницы
 * @see https://recsys.pages.rambler-co.ru/recommender/js/docs/functions/rcm.html#rcm-setMeta
 * @param userId - идентификатор авторизованного пользователя (то, что лежит в куке rccid)
 */
export const RCMsetMeta = (userId: string) =>
  safeEvent(() => window.rcm('setMeta', { userId }));
