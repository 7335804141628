import { checkCorrectUrl } from 'utils/checkCorrectUrl';

export type UtmParamsType = {
  source?: string;
  medium?: string;
  campaign?: string;
  content?: string;
  term?: string;
};

type GetUrlWithParamsArgumentType = {
  url: string | URL | undefined;
  params: UtmParamsType;
  deleteInitialUtm?: boolean;
};

const getUrl = (url: URL | string | undefined, base?: string): URL | null => {
  if (typeof url === 'string') {
    try {
      return new URL(url, base);
    } catch {
      return null;
    }
  }

  return url ?? null;
};

/**
 * Принимает url и возвращает url с utm-метками, модифицирует уже существующие параметры (если они есть).
 *
 * Usage: getWithUtmParams('https://google.com', {
 * medium: 'social',
 * compaign: 'buffer',
 * })
 * @param props - пропсы
 * @param props.url URL, можеть быть `string` или `new URL(...)`
 * @param props.params UTM-метки в виде объекта типа `{medium: 'hello', ...}`
 * @param props.deleteInitialUtm - удаление всех изначальных UTM-меток
 */
export const getUrlWithUtmParams = ({
  url,
  params,
  deleteInitialUtm = false,
}: GetUrlWithParamsArgumentType) => {
  let replaceProtocol = false;
  let urlObj = getUrl(url);

  if (
    !urlObj &&
    typeof url === 'string' &&
    !url.includes('http://') &&
    !url.includes('https://')
  ) {
    replaceProtocol = true;

    const urlWithReplacedSymbols = url.replace(/\s/g, '+').replace('://', '');

    urlObj = checkCorrectUrl(`http://${urlWithReplacedSymbols}`)
      ? new URL(`http://${urlWithReplacedSymbols}`)
      : null;
  }

  if (!urlObj) return `${url}`;

  const queryParams = urlObj.searchParams;
  const urlWithoutParams = replaceProtocol
    ? urlObj
        .toString()
        .replace(/http:\/\//, '')
        .replace(/\?.+/, '')
    : urlObj.toString().replace(/\?.+/, '');

  if (deleteInitialUtm) {
    [...queryParams.keys()].forEach((key) => queryParams.delete(key));
  }

  Object.entries(params).forEach(([key, value]) => {
    if (value) {
      queryParams.set(`utm_${key}`, value);
    }
  });

  return `${urlWithoutParams}?${queryParams}`;
};

const DOMAIN_STUB = 'https://news.rambler.ru';

const replaceUpdated = (str: string) => str.replace('updated=', 'updated');

/**
 * Изменение get-параметров в url
 * @param url - URL, можеть быть `string` или `new URL(...)`
 * @param params - объект с ключами-get-параметрами и значениями для изменения
 * @param withDomain - вернуть урл с доменом или без
 * @returns url с измененными get-параметрами
 */
export const changeGetParams = (
  url: string | URL,
  params: { [getParam: string]: string },
  withDomain: boolean = false,
) => {
  const urlObj = getUrl(url) || getUrl(url, DOMAIN_STUB);

  if (!urlObj) return '';

  Object.keys(params).forEach((key) => {
    const newValue = params[key];

    urlObj.searchParams.set(key, newValue);
  });

  if (withDomain) return replaceUpdated(urlObj.toString());

  return `${urlObj.pathname}${replaceUpdated(urlObj.search)}${urlObj.hash}`;
};
