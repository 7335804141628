import { RAMBLER_RESOURCES_IDS } from 'config/common/ramblerResourcesIds';
import { UtmParamsType } from 'utils/getUrlWithUtmParams';

type GetUtmParamsTopicPropsType = {
  projectAlias: ProjectType['alias'];
  isMobile: SettingsType['isMobile'];
  resourceId: ATClusterItem['resource_id'] | null;
};

/**
 * Получение utm параметров для передачи в функцию getUrlWithUtmParams для топика/источника
 * @see https://confluence.rambler-co.ru/pages/viewpage.action?pageId=39499811
 * @param props - пропсы
 * @param props.resourceId – id ресурса на который будет вести ссылка
 * @param props.projectAlias - alias проекта
 * @param props.isMobile – флаг мобильной версии
 */
export const getUtmParamsTopic = ({
  resourceId,
  projectAlias,
  isMobile,
}: GetUtmParamsTopicPropsType) => {
  const isRamblerResource = RAMBLER_RESOURCES_IDS.includes(Number(resourceId));

  const utmParamsTopic: UtmParamsType = {
    medium: 'source',
    source: `r${projectAlias}`,
  };

  if (isRamblerResource) {
    utmParamsTopic.campaign = 'self_promo';
    utmParamsTopic.source = isMobile
      ? `m${projectAlias}_media`
      : `${projectAlias}_media`;
    utmParamsTopic.content = isMobile
      ? `m${projectAlias}_media`
      : `${projectAlias}_media`;
  }

  return utmParamsTopic;
};
