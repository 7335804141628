import { COUNTERS } from 'config/common/counters/constants';
import { createNode } from 'utils/createNode';

const counters: CountersType & ResourcesCountersType =
  COUNTERS as ExtendedCountersType;

/**
 * Mediascope(TNS) счетчик
 * @param projectAlias - алиас проекта
 */
export const TNShit = (projectAlias: ProjectType['alias']) => {
  const randomValue = Math.floor(Math.random() * 999999999);

  createNode({
    tagName: 'img',
    src: `${COUNTERS.resources.counters.tns}${counters[projectAlias].tns}/${randomValue}`,
    isHidden: true,
  });
};
