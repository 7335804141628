const SCRAPPER_LINK_REGEXP = /<a.*?>(.*?)<\/a>/;

export const replaceLinks = (str: string | undefined) => {
  if (!str) return '';
  let newStr = str;

  while (true) {
    const scrapperLink = newStr.match(SCRAPPER_LINK_REGEXP);

    if (scrapperLink) {
      newStr = newStr.replace(scrapperLink[0], scrapperLink[1]);
    } else {
      break;
    }
  }

  return newStr;
};

/**
 * Замена href у ссылки при наличии redirect_url
 * @param string - параграф (строка) с ссылкой внутри;
 * @param projectAlias - алиас проекта.
 */
export const replacingStr = (
  string: string,
  projectAlias: ProjectType['alias'],
) =>
  string.replace(/href="([^"]+redirect_url[^"]+)"/g, (_, href) => {
    try {
      const requiredUrl = decodeURIComponent(href.split('redirect_url=')[1]);
      const parsedRequiredUrl = new URL(requiredUrl);

      parsedRequiredUrl.searchParams.append('utm_source', 'Rambler');
      parsedRequiredUrl.searchParams.append('utm_medium', projectAlias);
      parsedRequiredUrl.searchParams.append('utm_campaign', 'transition');

      return `href="${parsedRequiredUrl.toString()}"`;
    } catch {
      return 'href="https://www.rambler.ru/"';
    }
  });
