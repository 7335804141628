import {
  getShareInitParams,
  GetShareInitParamsProps,
} from '../getShareInitParams';

type InitShareButtonsPropsType = {
  buttonsNode: HTMLElement | null;
  handleFocus?: (event: Event) => void;
} & GetShareInitParamsProps;

/**
 * Функция, инициализирующая кнопки шаринга
 * @param props - пропсы
 * @param props.buttonsNode - node узел кнопок
 * @param props.handleFocus - слушатель на фокус элемента
 * @param props.clusterUrl - url кластера
 * @param props.clusterTitle - заголовок кластера
 * @param props.stylesForButton - мапка стилей для каждой кнопки отдельно
 * @param props.extraButtons - дополнительный список кнопок
 * @param props.setCounters - флаг установки счетчиков
 * @param props.customOrder - кастомный порядок соцсетей
 */
export const initShareButtons = ({
  buttonsNode,
  handleFocus,
  ...initParams
}: InitShareButtonsPropsType): Promise<Window['RamblerShare']> =>
  new Promise((resolve) => {
    const initScript = () => {
      const params = getShareInitParams(initParams);

      resolve(window.RamblerShare.init(buttonsNode, params));

      buttonsNode?.childNodes.forEach((element) => {
        const child = element as HTMLDivElement;

        child.setAttribute('tabIndex', '0');

        if (handleFocus) {
          child.addEventListener('focus', handleFocus);
        }
      });
    };

    if (window.RamblerShare) {
      initScript();
    } else {
      const script = document.createElement('script');

      script.onload = initScript;
      script.async = true;
      script.src = 'https://developers.rambler.ru/likes/v1/widget.js';

      document.head.appendChild(script);
    }
  });
