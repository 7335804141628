const BRANDING_SPEC_CLASS = 'with-branding';
const MINI_BILLBOARD_SPEC_CLASS = 'with-mini-billboard';

/**
 * Обертка над ядром-обраотчиком, обеспечивающая передачу имен классов напрямую.
 * @param bcClass - имя класса для брендирования;
 * @param mbClass - имя класса для мини-биллборда.
 *
 * Названия закодированы специально, чтобы в коде было не так ясно к чему они относятся.
 * Весь код ниже не ДОЛЖЕН содержать комментариев внутри.
 *
 * Уровнем ниже:
 * Ядро-обработчик функции добавления стилей.
 * Если рисуется брендирование - добавляет дополнительные стили для body.
 */
const brandCheckerCoreWrapped =
  (bcClass: string, mbClass: string) => (result: AdResultType) => {
    if (!result || !result.viewTypes) {
      return;
    }

    if (result.viewTypes.indexOf('Branding') !== -1) {
      document.body.classList.add(bcClass);
    }

    if (result.viewTypes.indexOf('Graph970x100') !== -1) {
      document.body.classList.add(mbClass);
    }
  };

export const brandCheckerCore = brandCheckerCoreWrapped(
  BRANDING_SPEC_CLASS,
  MINI_BILLBOARD_SPEC_CLASS,
);

/**
 * Тело функции добавления стилей вместе с обработкой коллбека отрисовки рекламы.
 * @param coreFunction - callback который имеет доступ к внешним импортам
 */
const brandCheckerBody = (coreFunction: (result: AdResultType) => void) => {
  const begunTimerId = setInterval(() => {
    if (window.Begun) {
      clearInterval(begunTimerId);
      window.Begun.Autocontext?.Callbacks.register({
        block: {
          drawComplete: (result: AdResultType) => {
            coreFunction(result);
          },
        },
      });
    }
  }, 10);
};

/** Тулза, ловящая момент установки брендирования и устанавливающая спец класс */
export const brandingChecker = () => `
      const checker = (${brandCheckerCoreWrapped.toString()})('${BRANDING_SPEC_CLASS}', '${MINI_BILLBOARD_SPEC_CLASS}');
      (${brandCheckerBody.toString()})(checker);
  `;
