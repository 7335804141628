import { getClusterSpeechText } from 'utils/getClusterSpeechText';

import { sentryClientSend } from './sentry/sentry.client';

const MAX_FAILURE_COUNT = 10;
const TEXT_TO_SPEECH_AVAIBILITY_CHECK_PERIOD_MS = 1000;

/**
 * Класс для реализации текстовоспроизведения
 */
class TextToSpeech {
  /**
   * Экземпляр интерфеса текстовоспроизведения
   */
  private speechController: SpeechSynthesis | null = null;

  /**
   * Экземпляр текста с настройками воспроизведения
   */
  private speechText: SpeechSynthesisUtterance | null = null;

  /**
   * Флаг активности класса и наличия API воспроизведения звука в клиенте
   */
  textToSpeechAvailable: boolean = false;

  constructor() {
    if (
      typeof window === 'undefined' ||
      !window.speechSynthesis ||
      !window.SpeechSynthesisUtterance
    )
      return;

    this.speechController = window.speechSynthesis;
    this.speechText = new SpeechSynthesisUtterance();

    this.speechText.volume = 1;
    this.speechText.rate = 0.9;
    this.speechText.pitch = 1;

    let rusVoice: SpeechSynthesisVoice | undefined;

    /**
     * По неизвестной причине временами getVoices может отдать значение,
     *  которое не принимается в this.speechText.voice.
     * Для этого тут есть try/catch
     */
    try {
      let failureCount = 0;

      const interval = setInterval(() => {
        rusVoice = window.speechSynthesis
          .getVoices()
          .find((voice) => voice.lang.toLowerCase().includes('ru'));

        if (rusVoice && this.speechText) {
          this.speechText.voice = rusVoice;
          clearInterval(interval);
        }

        if (failureCount > MAX_FAILURE_COUNT) {
          throw new Error('Не удалось подключить систему распознавания речи');
        }

        failureCount += 1;
      }, TEXT_TO_SPEECH_AVAIBILITY_CHECK_PERIOD_MS);

      this.textToSpeechAvailable = true;
    } catch (error) {
      this.textToSpeechAvailable = false;

      /**
       * После сбора метрик проверить, какие данные собираются в сентри
       */
      sentryClientSend({
        error: error as Error,
        level: 'error',
        extra: {
          voice: rusVoice?.name ?? String(rusVoice),
        },
      });
    }

    window.addEventListener('beforeunload', () => this.stopSpeech());
  }

  /**
   * Добавление текста для воспроизведения
   * @param text - строка с текстом для воспроизведения
   */
  addTextToSpeech(text: string) {
    if (this.textToSpeechAvailable && this.speechText) {
      this.speechText.text = text;
    }
  }

  /**
   * Добавление текста кластера для воспроизведения
   * @param clusterTitle - заголовок кластера;
   * @param clusterBody - тело кластера;
   */
  addClusterTextToSpeech(
    clusterTitle: Cluster['longTitle'],
    clusterBody: Cluster['body'],
  ) {
    if (this.textToSpeechAvailable) {
      const speechText = getClusterSpeechText(clusterTitle, clusterBody);

      this.addTextToSpeech(speechText);
    }
  }

  /**
   * Запуск воспроизведения текста
   * @param callback - метод, срабатывающий по окончанию воспроизведения
   */
  startSpeech(callback?: any) {
    const { speechText, textToSpeechAvailable, speechController } = this;

    if (!speechText) return;

    if (callback) {
      speechText.onend = callback;
    }

    if (textToSpeechAvailable && speechController) {
      speechController.speak(speechText);
    }
  }

  /**
   * Пауза воспроизведения текста
   */
  pauseSpeech() {
    if (this.textToSpeechAvailable && this.speechController) {
      this.speechController.pause();
    }
  }

  /**
   * Продолжить воспроизведения текста.
   * @param callback - коллбек, срабатывающий при завершении чтения текста.
   */
  resumeSpeech(callback?: any) {
    if (callback && this.speechText) {
      this.speechText.onend = callback;
    }

    if (this.textToSpeechAvailable && this.speechController) {
      this.speechController.resume();
    }
  }

  /**
   * Остановка воспроизведения текста
   */
  stopSpeech() {
    if (this.textToSpeechAvailable && this.speechController) {
      this.speechController.cancel();
    }
  }
}

export const textToSpeech: TextToSpeech = new TextToSpeech();
