type AliasType = string | undefined;

type DefaultDataType = {
  id: number;
  alias?: string;
};

type SelectorType<
  ReturnType extends number | AliasType,
  T extends DefaultDataType = DefaultDataType,
> = (val: T) => ReturnType;

type InitOptionsType<T extends DefaultDataType = DefaultDataType> = {
  selectId: SelectorType<number, T>;
  selectAlias: SelectorType<AliasType, T>;
};

type ObjectWithChartToIdMapType<
  MapName extends string,
  MapObject extends object,
> = {
  [K in MapName]: MapObject;
};

const MAP_NAME = 'charToId';

/**
 * Тулза для создания генератора мапки типа "алиас - id".
 * Предоставляет интерфейс для добавления новых значений,
 *  обновления старых.
 * Аналогичен адаптеру редакса.
 *
 * Позволяет быстро конвертировать по alias данных его id.
 */
class CharToId<DataType extends DefaultDataType = DefaultDataType> {
  private _selectId: SelectorType<number, DataType>;

  private _selectAlias: SelectorType<AliasType, DataType>;

  private _map: Record<string, number>;

  constructor({ selectId, selectAlias }: InitOptionsType<DataType>) {
    this._selectId = selectId;
    this._selectAlias = selectAlias;
    this._map = {} as typeof this._map;
  }

  /**
   * Метод получения модифицированного стейта redux,
   * внутри которого лежит мапка alias - id.
   * @param defaultState - оригинальный стейт.
   * @returns модифицированный стейт с мапкой внутрию
   */
  public getInitialState<T extends object = object>(defaultState: T = {} as T) {
    return {
      ...defaultState,
      [MAP_NAME]: this._map,
    };
  }

  /**
   * Метод добавления/обновления сущности в мапке.
   * Если alias уже присутствует, то его значение просто обновляется.
   * Если alias не существует, то создается новый.
   *
   * Передаваемый объект должен содержать те поля id и alias,
   * что были переданы при создании генератора.
   * @param state - стейт redux, что надо модифицировать;
   * @param data - дата, из которой будут извлечены id и alias,
   *  на основе которых будет модифицирована мапка.
   */
  public upsertOne(
    state: ObjectWithChartToIdMapType<typeof MAP_NAME, typeof this._map>,
    data: DataType,
  ) {
    const value = this._selectAlias(data);

    if (typeof value === 'undefined') {
      if (__SERVER__) {
        console.error('Невозможно получить alias для объекта ', data);
      }

      return;
    }

    const clone = {
      ...this._map,
    };

    clone[value] = this._selectId(data);

    if (!(MAP_NAME in state)) {
      if (__SERVER__) {
        console.error('Стейт не содержит', MAP_NAME, state);
      }

      return;
    }

    this._map = clone;

    state[MAP_NAME] = clone;
  }

  /**
   * Метод, аналогичный upsertOne, но делает то же самое для массива данных.
   *
   * Передаваемый объекты массива должны содержать те поля id и alias,
   * что были переданы при создании генератора.
   * @param state - стейт redux, что надо модифицировать;
   * @param data - массив с данными, из которых будут извлечены id и alias,
   *  на основе которых будет модифицирована мапка.
   */
  public upsertMany(
    state: ObjectWithChartToIdMapType<typeof MAP_NAME, typeof this._map>,
    data: DataType[],
  ) {
    data.forEach((val) => this.upsertOne(state, val));
  }

  /**
   * Генератор селектора, который позволяет извлекать id по переданному alias.
   * @param localStateSelector - селектор, для извлечения
   *  подстейта, в котором находится сгенерированная мапка alias - id.
   * @returns селектор, который выдает id объекта по переданному alias, если таковой есть.
   */
  public createAliasToIdSelector<State extends object = object>(
    localStateSelector: (
      state: State,
    ) => ObjectWithChartToIdMapType<typeof MAP_NAME, typeof this._map>,
  ) {
    return (alias: string) => (state: State) =>
      localStateSelector(state)[MAP_NAME][alias];
  }
}

/**
 * Генератор генератора мапки alias - id.
 * По-умолчанию передаваемые данные должны содержать поля alias и id.
 * Если id или alias имеют другие названия, используйте опционалные селекторы.
 * @param props - пропсы
 * @param props.selectId - функция для получения id, по которому будет
 *  строиться мапка. По-умолчанию извлекатеся поле id;
 * @param props.selectAlias - функция для получения id объекта, по которму
 *  будет строиться мапка. По-умолчанию извлекается поле alias.
 * @returns экземпляр генератора, который управляет мапкой alias - id.
 */
export function createCharToIdMap<
  DataType extends DefaultDataType = DefaultDataType,
>({
  selectId = (val) => val.id,
  selectAlias = (val) => val.alias,
}: Optional<InitOptionsType<DataType>> = {}) {
  return new CharToId<DataType>({
    selectId,
    selectAlias,
  });
}
