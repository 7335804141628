const SHARE_IMAGE_URL = 'https://img-renderer.rambler.ru/api/generate/media/';
const DEFAULT_FORMAT = 'png';

type GetShareImageUrlType = (props: {
  clusterId?: Cluster['id'] | null;
}) => string;

/**
 * Получение урла кастомной картинки шаринга
 * @param props - пропсы
 * @param props.clusterId - id кластера
 */
export const getShareImageUrl: GetShareImageUrlType = ({ clusterId }) => {
  if (!clusterId) return '';

  const searchParams = new URLSearchParams();

  searchParams.append('clusterId', clusterId);
  searchParams.append('format', DEFAULT_FORMAT);

  return `${SHARE_IMAGE_URL}?${searchParams.toString()}`;
};
