/**
 * Сбор кликов в редис
 * @param clusterId - id кластера
 * @param projectId - id проекта
 */
export const glick = (clusterId: Card['id'], projectId: ProjectType['id']) => {
  const xhr = new XMLHttpRequest();

  xhr.open(
    'POST',
    `/clicks/?cluster_id=${clusterId}&project_id=${projectId}`,
    true,
  );
  xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
  xhr.send(`id=${clusterId}`);
  setTimeout(() => xhr.abort(), 1000);
};
