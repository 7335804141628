import {
  browserTracingIntegration,
  captureException,
  getCurrentScope,
  init,
  reactRouterV5BrowserTracingIntegration,
  withScope,
} from '@sentry/react';
import Cookies from 'js-cookie';

import { UserType, sendToSentry } from './index';

type InitSentryClientPropsType = {
  profile: Runtime['profile'];
  isMobile: SettingsType['isMobile'];
  isBot: SettingsType['isBot'];
  sentryConfig: SentryConfigType;
};

/**
 * Инициализация Sentry на клиенте
 * @param props - пропсы
 * @param props.profile - тип профайла стенда
 * @param props.isMobile - флаг мобильной версии
 * @param props.isBot - флаг, что это бот делает запрос
 * @param props.sentryConfig - конфигурация для sentry
 */
export const initSentryClient = ({
  profile,
  isMobile,
  isBot,
  sentryConfig,
}: InitSentryClientPropsType) => {
  const { dsn, enabled, sampleRate, tracesSampleRate } = sentryConfig;

  if (!enabled) return;

  const ruid = Cookies.get('ruid');
  const user: UserType = {};

  if (ruid) {
    user.id = ruid;
  }

  init({
    dsn,
    sampleRate,
    release: __SENTRY_RELEASE__,
    environment: profile,
    tracesSampleRate,
    // Пробное значение глубины. Можно изменять
    normalizeDepth: 5,

    /**
     * Взято из
     * @see https://docs.sentry.io/platforms/javascript/guides/react/features/react-router/?original_referrer=https%3A%2F%2Fyandex.ru%2F#react-router-v4v5
     */
    integrations: [
      browserTracingIntegration(),
      reactRouterV5BrowserTracingIntegration({ history: window.history }),
    ],
    // TODO(NEWS-11986): filter capirs
    /*
     * beforeSend(event, hint) {
     *   return event;
     * },
     */
  });

  getCurrentScope().setUser(user);
  getCurrentScope().setTag('app', 'client');
  getCurrentScope().setTag('user_agent', window.navigator.userAgent);
  getCurrentScope().setTag('is_mobile', String(isMobile));
  getCurrentScope().setTag('is_bot', String(isBot));
  getCurrentScope().setTag('viewport', String(window.innerWidth));
};

/**
 * Функция отправки ошибки с браузера с настраиваемым scope.
 * @param props.withScope - callback для конфигурации;
 * @param props.captureException - функция отправки сообщения.
 */
export const sentryClientSend = sendToSentry({ withScope, captureException });
