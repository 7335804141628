const WHITESPACE_LENGTH = 1;

type TextCroppingByWordArgumentType = {
  str: string | undefined;
  maxLength?: number;
  charEnd?: string;
};

/**
 * Обрезание строки по максимальной длине с добавлением символов в конце
 * @param str - строка
 * @param maxLength - максимальная длина
 * @param charEnd - закрывающие символы
 */

export const textCroppingByWord = ({
  str,
  maxLength = 200,
  charEnd = '...',
}: TextCroppingByWordArgumentType) => {
  if (!str) return '';
  if (str.length < maxLength) return str;

  const words = str.split(' ');
  let currentStringLength = 0;
  let firstExcludedWordIndex = 0;

  for (let i = 0; i < words.length; i++) {
    const currentWord = words[i];

    currentStringLength += currentWord.length + WHITESPACE_LENGTH;
    firstExcludedWordIndex = i;
    if (currentStringLength + charEnd.length > maxLength) break;
  }

  return `${words.slice(0, firstExcludedWordIndex).join(' ')}${charEnd}`;
};
