import {
  onLCP,
  onCLS,
  onFCP,
  onTTFB,
  onINP,
  CLSMetric,
  FCPMetric,
  INPMetric,
  LCPMetric,
  TTFBMetric,
} from 'web-vitals';

import { APP_TYPE } from 'config/common/devices';
import { CLIENT_METRICS_PATHS } from 'server/collectors/prometheus/constants';

import { MetricDCLType, onDCL } from './metrics';

const sendMetrics = (body: string) => {
  if (navigator.sendBeacon) {
    // Все ради заголовка, который обозначает, что данные ниже - json
    const headers = {
      type: 'application/json',
    };

    const blob = new Blob([body], headers);

    navigator.sendBeacon(CLIENT_METRICS_PATHS.WebVitals, blob);
  } else {
    fetch(CLIENT_METRICS_PATHS.WebVitals, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body,
      keepalive: true,
    });
  }
};

const METRICS_COLLECTORS = [onCLS, onFCP, onTTFB, onINP, onDCL, onLCP];

type MetricType =
  | CLSMetric
  | FCPMetric
  | TTFBMetric
  | INPMetric
  | MetricDCLType
  | LCPMetric;

/**
 * Функция инициализации отправки браузерных метрик при запуске клиента.
 * @param pageName название страницы;
 * @param deviceType - тип устройства;
 */
export const initBrowserMetrics = (pageName: string, deviceType: APP_TYPE) => {
  const sendQueue = new Set<MetricType>();

  const flushSendQueue = () => {
    if (sendQueue.size <= 0) return;

    sendMetrics(
      JSON.stringify({
        pageName,
        metrics: [...sendQueue].map((metric) => ({
          name: metric?.name ?? '',
          value: metric?.value ?? '',
        })),
        deviceType,
      }),
    );

    sendQueue.clear();
  };

  const addToSendQueue: (metric: MetricType) => void = (metric) => {
    sendQueue.add(metric);

    if (sendQueue.size >= METRICS_COLLECTORS.length) {
      flushSendQueue();
    }
  };

  METRICS_COLLECTORS.forEach((getMertic) => getMertic(addToSendQueue));

  document.addEventListener('visibilitychange', () => {
    if (document.visibilityState === 'hidden') {
      flushSendQueue();
    }
  });

  // Safari хак (вместо visibilitychange)
  document.addEventListener('pagehide', flushSendQueue);
};
