import {
  selectIsMobile,
  selectProjectAlias,
} from 'common/redux/runtime/selectors';
import { COUNTERS } from 'config/common/counters/constants';
import { PROJECT_ALIASES } from 'config/common/project/typings';
import { useAppSelector } from 'store/hooks';

import { COMMON_COUNTER_ID, COUNTER_ID } from './constants';
import { getCountersIDs, createCountersParams } from './utils';

/**
 * Теги инкрементации счетчиков.
 * @param runtime - объект конфигурации приложения;
 * @param url - урл страницы, которую посетил.
 */
export const yaHit = (runtime: Runtime, url: string) => {
  const state = { runtime } as AppState;

  const projectAlias = selectProjectAlias(state);
  const isMobile = selectIsMobile(state);

  const countersIDs = getCountersIDs(projectAlias);

  const params = createCountersParams({
    isMobile,
    state,
  });

  // инкрементация общего счетчика
  window.ym(COMMON_COUNTER_ID, 'hit', url, { params });

  // инкрементация счетчиков вертикали
  countersIDs.forEach((counterId: number) => {
    window.ym(`${counterId}`, 'hit', url, { params });
  });
};

/**
 * Передача информации о достижении цели яндекс-метрики
 * https://yandex.ru/support/metrica/objects/reachgoal.html
 * https://yandex.ru/support/metrica/objects/_method-reference.html
 * @param runtime - объект конфигурации приложения
 * @param type - тип счётчика
 * @param target - идентификатор цели
 * @param params - параметры визита
 */
export const yaReachGoal = (
  runtime: Runtime,
  type: COUNTER_ID,
  target: string,
  params?: Record<string, unknown>,
) => {
  if (!type) {
    return;
  }

  const state = { runtime } as AppState;

  const projectAlias = selectProjectAlias(state);
  const isMobile = selectIsMobile(state);

  const counterId = COUNTERS[projectAlias][type];

  if (window.ym) {
    window.ym(counterId, 'reachGoal', target, {
      ...createCountersParams({
        state,
        params,
        isMobile,
      }),
    });
  }
};

export enum YM_PARAMS_EVENT_TYPE {
  Params = 'params',
  UserParams = 'userParams',
}

type YmParamsType = {
  type?: COUNTER_ID;
  runtime: Runtime;
  params?: {};
  ymCommonOptions?: {};
  eventType?: YM_PARAMS_EVENT_TYPE;
  withSplits?: boolean;
};

/**
 * Передача произвольных параметров визита.
 * @see https://yandex.ru/support/metrica/objects/params-method.html
 * @param props - пропсы
 * @param props.type - тип счётчика. Если не указан, то вызываются все доступные счетчики;
 * @param props.runtime - объект конфигурации приложения;
 * @param props.eventType - способ тегирования данных для YM
 * @param props.params - параметры визита, заданные извне;
 * @param props.ymCommonOptions - общие параметры, передающиеся на в счетчики на том же уровне, что и props.params;
 * @param props.withSplits - флаг, что надо использовать параметры визита, основанном на экспериментах, в которые попал юзер.
 */
export const ymParams = ({
  type,
  runtime,
  eventType = YM_PARAMS_EVENT_TYPE.Params,
  params: rawParams = {},
  ymCommonOptions = {},
  withSplits,
}: YmParamsType) => {
  const state = { runtime } as AppState;

  const isMobile = selectIsMobile(state);

  const params = withSplits
    ? createCountersParams({
        isMobile,
        state,
        params: rawParams,
      })
    : rawParams;

  if (!window.ym) return;

  const projectAlias = selectProjectAlias(state);

  if (type) {
    const counterId = COUNTERS[projectAlias][type];

    window.ym(counterId, eventType, { ...ymCommonOptions, ...params });

    return;
  }

  const countersIDs = getCountersIDs(projectAlias);

  // инкрементация общего счетчика
  window.ym(COMMON_COUNTER_ID, eventType, { ...ymCommonOptions, params });

  // инкрементация счетчиков вертикали
  countersIDs.forEach((counterId: number) => {
    window.ym(`${counterId}`, eventType, { ...ymCommonOptions, params });
  });
};

/**
 * Получения id счетчика вертикали.
 * @param type - тип счётчика;
 * @param projectAlias - имя проекта.
 * @returns - номер счетчика.
 */
export const getCounterId = (type: COUNTER_ID, projectAlias: PROJECT_ALIASES) =>
  COUNTERS[projectAlias][type];

/**
 * Хук-обертка для получения id счетчика.
 * Берет данные о проекте из стора.
 * @param type - тип счётчика.
 * @returns номер счетчика.
 */
export const useCounterId = (type: COUNTER_ID) => {
  const projectAlias = useAppSelector(selectProjectAlias);

  return getCounterId(type, projectAlias);
};
