/**
 * Закрывает возможность ботам отсылать запрос, чтобы убрать лишнюю информацию из статистики и лишнюю нагрузку.
 * @param callback - колбэк
 */
export const checkBotsDecorator = (callback: () => void) => {
  // useragent ботов
  const bots = [
    'AdsBot-Google-Mobile',
    'AdsBot-Google',
    'Mediapartners-Google',
    'Googlebot',
    'YandexSearch',
    'YandexBot',
  ];

  const result = bots.find((item) => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    const str = item.toLowerCase();

    return userAgent.indexOf(str) !== -1;
  });

  if (!result && callback) {
    callback();
  }
};
