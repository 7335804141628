import {
  selectUserId,
  selectHumanCenteredStrategyValue,
} from 'common/redux/runtime/selectors';
import { COUNTERS } from 'config/common/counters/constants';
import {
  HUMAN_CENTERED_STRATEGY_SPLIT_VALUES,
  SPLITS_NAMES,
} from 'config/common/splits/typings';

import { COUNTER_ID } from './constants';
import { paramsModifier } from './paramsGenerator';

/**
 * Получение списка IDs счетчиков
 * @param projectAlias - алиас проекта;
 */
export const getCountersIDs = (projectAlias: ProjectType['alias']) =>
  [COUNTER_ID.Portal, COUNTER_ID.WebMobile].map((counterName) =>
    Number(COUNTERS[projectAlias][counterName]),
  );

type CreateCountersParams = {
  state: AppState;
  isMobile: boolean;
  params?: Record<string, unknown>;
};

/**
 * Генерация параметров для счетчиков с учетом сплитования.
 * @param props - пропсы
 * @param props.state - стейт приложения;
 * @param props.params - дополнительные параметры приложения;
 * @param props.isMobile - тип устройства.
 *
 * Мысль: может быть стоит удалить все внешние параметры кроме стейта
 *  и все получать через селекторы?
 */
export const createCountersParams = ({
  state: iState,
  params,
  isMobile = false,
}: CreateCountersParams): object =>
  paramsModifier({
    params,
    state: iState,
    isMobile,
  })
    .addParams({
      name: 'authorization',
      selector: selectUserId,
      mapToYMValue: (value) => (value ? 'ID_authorized' : 'ID_unauthorized'),
      isMobile,
    })
    .addParams({
      name: SPLITS_NAMES.humanCenteredStrategy,
      selector: selectHumanCenteredStrategyValue,
      mapToYMValue: (value) => {
        switch (value) {
          case HUMAN_CENTERED_STRATEGY_SPLIT_VALUES.off:
            return 'redesign_off';
          case HUMAN_CENTERED_STRATEGY_SPLIT_VALUES.on:
            return 'redesign_1';
          case HUMAN_CENTERED_STRATEGY_SPLIT_VALUES.on_4:
            return 'redesign_4';
          case HUMAN_CENTERED_STRATEGY_SPLIT_VALUES.on_1_4:
            return 'redesign_1_4';
          default:
            return value;
        }
      },
      isMobile,
    })
    .getParams();
