import { getUrlWithUtmParams } from '../getUrlWithUtmParams';
import { domainGenerator } from '../urlGenerator';

type GenerateSourceLinkArgumentType = {
  originalCopiedText: string;
  domainConfig: DomainConfigType;
  cluster: Cluster;
  topicAlias?: string;
  projectId: ProjectType['id'];
  projectAlias: ProjectType['alias'];
  isMobile: SettingsType['isMobile'];
};

/**
 * Возращает скопированный текст + ссылка на текущую страницу если длина больше 30 символов, иначе просто скопированный текст
 * @param props - пропсы
 * @param props.originalCopiedText текст который юзер хотел скопировать
 * @param props.domainConfig - объект данных о домене
 * @param props.cluster - объект данных о кластере
 * @param props.topicAlias - alias топика
 * @param props.projectId - id проекта
 * @param props.projectAlias - alias проекта
 * @param props.isMobile – флаг мобильной версии
 */
export const generateSourceLink = ({
  originalCopiedText,
  domainConfig,
  cluster,
  topicAlias = '',
  projectId,
  projectAlias,
  isMobile,
}: GenerateSourceLinkArgumentType) => {
  if (originalCopiedText.length < 30) {
    return originalCopiedText;
  }

  const domain = domainGenerator(domainConfig, projectId);
  const sourceLink = `${domain}/${topicAlias}/${cluster.id}/`;
  const sourceLinkWithUtm = getUrlWithUtmParams({
    url: sourceLink,
    params: {
      content: isMobile ? `m${projectAlias}_media` : `${projectAlias}_media`,
      medium: 'read_more',
      source: 'copylink',
    },
  });

  return `${originalCopiedText} Об этом сообщает "Рамблер". Далее: ${sourceLinkWithUtm}`;
};

type GetNewTextType = (originalText: string) => string;

/**
 * Вешает 'copy' event handler на элемент, который модифицирует текст буфера обмена с помощью функции @param getNewText
 * @param listenOn элемент на который вешать copy event
 * @param getNewText колбек, принимает скопированный текст и возвращает модифицированный
 */
const modifyClipboardOnCopy = (
  listenOn: HTMLElement,
  getNewText?: GetNewTextType,
) => {
  listenOn.addEventListener('copy', (e) => {
    const originalText = window.getSelection()?.toString() || '';

    e.clipboardData?.setData(
      'text/plain',
      getNewText ? getNewText(originalText) : originalText,
    );

    e.preventDefault();
  });
};

type AddSourceLinkToClipboardArgumentType = {
  listenOn: HTMLElement | null;
  domainConfig: DomainConfigType;
  cluster: Cluster;
  topicAlias?: string;
  projectId: ProjectType['id'];
  projectAlias: ProjectType['alias'];
  isMobile: SettingsType['isMobile'];
};

/**
 * Вешает на `listenOn` 'copy' ивент и добавляет в буфер обмена ссылку на источник новости при копировании текста больше 30 символов
 * @param props - пропсы
 * @param props.listenOn - элемент на который вешать copy event
 * @param props.domainConfig - объект данных о домене
 * @param props.cluster - объект данных о кластере
 * @param props.topicAlias - alias топика
 * @param props.projectId - id проекта
 * @param props.projectAlias - alias проекта
 * @param props.isMobile – флаг мобильной версии
 */
export const addSourceLinkToClipboard = ({
  listenOn,
  domainConfig,
  cluster,
  topicAlias,
  projectId,
  projectAlias,
  isMobile,
}: AddSourceLinkToClipboardArgumentType) => {
  if (!cluster || !listenOn) return '';

  return modifyClipboardOnCopy(listenOn, (originalText) =>
    generateSourceLink({
      originalCopiedText: originalText,
      domainConfig,
      cluster,
      topicAlias,
      projectId,
      projectAlias,
      isMobile,
    }),
  );
};
