import {
  selectHumanCenteredStrategyValueFromSplits,
  selectIsMobile,
  selectSplits,
} from 'common/redux/runtime/selectors';
import { APP_TYPE } from 'config/common/devices';
import { PROJECT_ALIASES } from 'config/common/project/typings';

import {
  mediaTop100HumanCenteredStrategyDesk,
  mediaTop100HumanCenteredStrategyMob,
} from './constants';

/**
 * Генерирует строку всех возможных аттрибутов для топ100 медиа
 */
const getAllTop100MediaAttributes = () =>
  Object.values(PROJECT_ALIASES).reduce((attributes, projectAlias) => {
    attributes.push(`${projectAlias}_media-desktop`);
    attributes.push(`${projectAlias}_media-mobile`);

    return attributes;
  }, [] as string[]);

/**
 * Функция для получения полного массива аттрибутов топ100.
 */
export const getTop100Dataset = () => {
  const top100MediaAttributes = getAllTop100MediaAttributes();

  return [
    'blocks',
    ...top100MediaAttributes,
    'logo',
    'cerber-topline',
    'cerber-topline-mob',
    'cerber-search',
    'cerber-footer',
    'cerber-footer-mob',
    'cerber',
    'mc',
  ];
};

/**
 * Функция для получения полного массива аттрибутов топ100.
 * @param state - стейт приложения
 */
export const getTop100Splits = (state: AppState) => {
  const isMobile = selectIsMobile(state);
  const splits = selectSplits(state);

  const splitsStr = '';
  const splitsPlatform = isMobile ? APP_TYPE.mobile : APP_TYPE.desktop;
  const value = selectHumanCenteredStrategyValueFromSplits(splits);

  const splitsResult = [splitsPlatform, splitsStr];

  if (value) {
    const humanCenteredStrategyDesk =
      mediaTop100HumanCenteredStrategyDesk[value];
    const humanCenteredStrategyMob = mediaTop100HumanCenteredStrategyMob[value];

    splitsResult.push(
      isMobile ? humanCenteredStrategyMob : humanCenteredStrategyDesk,
    );
  }

  return splitsResult;
};
