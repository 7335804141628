export type GetShareInitParamsProps = {
  clusterUrl: string;
  clusterTitle: string;
  styles: RamblerShareWidgetStylesType;
  shareImageUrl?: string;
  stylesForButton?: RamblerShareWidgetStylesForButtonType;
  extraButtons?: string[];
  setCounters?: boolean;
  customOrder?: string[];
};

/**
 * Функция, возращающая объект с параметрами для share buttons
 * @param props - пропсы
 * @param props.clusterUrl - url кластера
 * @param props.clusterTitle - заголовок кластера
 * @param props.styles - объект общих стилей для кнопок
 * @param props.stylesForButton - стили для конкретной кнопки
 * @param props.shareImageUrl - картинка для шаринга
 * @param props.extraButtons - дополнительный список кнопок
 * @param props.setCounters - флаг установки счетчиков
 * @param props.customOrder - кастомный порядок соцсетей
 */
export const getShareInitParams = ({
  clusterUrl: url,
  clusterTitle: title,
  styles,
  extraButtons = [],
  shareImageUrl,
  stylesForButton = {},
  setCounters = false,
  customOrder,
}: GetShareInitParamsProps) => ({
  url,
  style: styles,
  utm: 'utm_medium=social',
  counters: setCounters,
  buttons: customOrder || [
    /*
     * скрыто в https://jira.rambler-co.ru/browse/NEWS-10369
     * 'facebook',
     */
    'vkontakte',
    'odnoklassniki',
    'whatsapp',
    ...extraButtons,
  ],

  /*
   * facebook: {
   *   utm: 'utm_source=fbsharing&utm_medium=social',
   *   useRamblerIdServerRequest: true,
   *   style: stylesForButton.facebook
   * },
   */
  vkontakte: {
    utm: 'utm_source=vksharing&utm_medium=social',
    style: stylesForButton.vkontakte,
    image: `${shareImageUrl}&social=vk`,
  },
  odnoklassniki: {
    utm: 'utm_source=oksharing&utm_medium=social',
    style: stylesForButton.odnoklassniki,
    image: shareImageUrl,
  },
  viber: {
    utm: 'utm_source=vbsharing&utm_medium=social',
    style: stylesForButton.viber,
    image: shareImageUrl,
  },
  livejournal: {
    utm: 'utm_source=ljsharing&utm_medium=social',
    style: stylesForButton.livejournal,
    image: shareImageUrl,
  },
  whatsapp: {
    utm: 'utm_source=washaring&utm_medium=social',
    style: stylesForButton.whatsapp,
    image: shareImageUrl,
  },
  telegram: {
    style: stylesForButton.telegram,
  },

  /*
   * скрыто в https://jira.rambler-co.ru/browse/NEWS-10369
   * twitter: {
   *   utm: 'utm_source=twsharing&utm_medium=social',
   *   style: stylesForButton.twitter
   * },
   */
  email: {
    utm: 'utm_source=emailsharing&utm_medium=social',
    style: stylesForButton.email,
    image: shareImageUrl,
  },
  copy: {
    utm: 'utm_source=copysharing&utm_medium=social',
    style: stylesForButton.copy,
    image: shareImageUrl,
  },
  title,
});
