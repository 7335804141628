import { PROJECT_ALIAS_BY_ID } from 'config/common/project/constants';
import { PROJECT_ALIASES } from 'config/common/project/typings';
import { PORT } from 'config/profiles/local';

export enum PROXY_DOMAINS {
  Avtorambler = 'avtorambler',
  Autorambler = 'autorambler',
  Rns = 'finance-rambler',
  Redigo = 'redigo',
  RamblerUA = 'news.rambler.ua',
  RamblerUALocal = 'newsua',
}

// @PROXY_CHECK - править тут
const PROXY_TO_PROJECT_ALIASES_MAP: Record<PROXY_DOMAINS, PROJECT_ALIASES> = {
  [PROXY_DOMAINS.Avtorambler]: PROJECT_ALIASES.Auto,
  [PROXY_DOMAINS.Autorambler]: PROJECT_ALIASES.Auto,
  [PROXY_DOMAINS.RamblerUA]: PROJECT_ALIASES.News,
  [PROXY_DOMAINS.RamblerUALocal]: PROJECT_ALIASES.News,
  [PROXY_DOMAINS.Redigo]: PROJECT_ALIASES.Travel,
  [PROXY_DOMAINS.Rns]: PROJECT_ALIASES.Finance,
};

const DOMAINS_LIST = Object.values(PROXY_DOMAINS);

/**
 * Функция, проверяющая, относится ли переданная строка с урлом/доменом к списку проксируемых.
 * @param url - строка для проверки.
 */
const checkProxyDomainInUrl = (url: string) =>
  DOMAINS_LIST.find((domain) => url.includes(domain));

/**
 * Функция, приводящая постфикс урла (то есть оставшуюся часть урла, без алиаса),
 * к корректному виду, если вертикаль оригинального урла не совпадает с вертикалью,
 * которая сейчас проксируется.
 *
 * То есть, пример:
 * Дано:
 * Урл, на котором находится юзер - finance-rambler
 * Урл, на который ведет ссылка - news.rambler.ru
 *
 * Если урл не подменить, то ссылка будет вести на finance-rambler.ru/, что не совсем корректно,
 * но так как постфикс уже является finance-rambler.ru, то его нужно заменить на корректный rambler.ru
 * @param postfix - англоязычное наименование проекта.
 * @param projectAlias - англоязычное наименование проекта.
 */
const getCorrectPostfix = (
  postfix: string,
  projectAlias: ProjectType['alias'],
) => {
  const proxyDomain = checkProxyDomainInUrl(postfix);

  /**
   * Если постфикс вообще не относится к прокси,
   *   просто отдаем обычный постфикс с алиасом.
   */
  if (!proxyDomain) return `${projectAlias}${postfix}`;

  /**
   * Если постфикс относится к той вертикали, которую проксирует,
   *  то просто отдаем постфикс без модификаций, ведь все корректно
   */
  if (PROXY_TO_PROJECT_ALIASES_MAP[proxyDomain] === projectAlias)
    return postfix;

  /**
   * Если же постфикс нужно модифицировать и мы на стейдже/локалке
   *  то подменяем лишь последний уровень на корректную вертикаль.
   *
   * Внимание, это не актуально для news.rambler.ua, ибо для него нет ни стейджа, ни локалки.
   */
  if (postfix.includes('stage') || postfix.includes('eve'))
    return postfix.replace(proxyDomain, projectAlias);

  /**
   * Если это продовый постфикс, и его вертикаль не совпадает с нормальной вертикалью,
   *  то генерируем домен с нуля.
   */
  return `${projectAlias}.rambler.ru`;
};

/**
 * Ядро генератора домена для id проекта
 * @param domainConfig - объект данных о домене
 * @param projectAlias - строчка с алиасом вертикали
 */
export const domainGeneratorCore = (
  domainConfig: DomainConfigType,
  projectAlias: ProjectType['alias'],
) => {
  const { port, protocol, prefix, postfix } = domainConfig;

  const strPort = port ? `:${port}` : '';

  const url = getCorrectPostfix(postfix, projectAlias);

  return `${protocol}://${prefix}${url}${strPort}`;
};

type DomainGeneratorType = (
  domainConfig: DomainConfigType,
  projectId: ProjectType['id'] | null,
) => string;

/**
 * Генератор домена для переданного ID проекта
 * @param domainConfig - объект данных о домене
 * @param projectId - ID проекта, для которого генерировать домен
 */
export const domainGenerator: DomainGeneratorType = (
  domainConfig,
  projectId,
) =>
  projectId
    ? domainGeneratorCore(domainConfig, PROJECT_ALIAS_BY_ID[projectId])
    : '';

type GenerateClusterUrlType = (props: {
  domainConfig: DomainConfigType;
  clusterId: Card['id'] | null | undefined;
  normalizedTitle: string | undefined;
  topic: TopicType | undefined;
  addDomain?: boolean;
}) => string;

/**
 * Генератор урла на страницу кластера
 * @param props - пропсы
 * @param props.domainConfig - данные о домене проекта
 * @param props.clusterId - id текущего кластера
 * @param props.normalizedTitle - чпу кластера
 * @param props.topic - объект данных о топике кластера
 * @param props.addDomain - генерация урла с доменом или без
 */
export const generateClusterUrl: GenerateClusterUrlType = ({
  domainConfig,
  normalizedTitle,
  clusterId,
  topic,
  addDomain = true,
}) => {
  if (!topic?.alias || !clusterId) return '';

  const { project_id: projectId, alias } = topic;
  const normalize = normalizedTitle ? `-${normalizedTitle}` : '';

  if (addDomain && projectId) {
    const domain = domainGenerator(domainConfig, projectId);

    return `${domain}/${alias}/${clusterId}${normalize}/`;
  }

  return `/${alias}/${clusterId}${normalize}/`;
};

type AbsoluteUrlType = (props: {
  projectId: ProjectType['id'];
  domainConfig: DomainConfigType;
  url: string;
}) => string;

/**
 * Генерация абсолютного пути с подстановкой домена в начало урла
 * @param props - пропсы
 * @param props.domainConfig - объект данных о домене
 * @param props.projectId - id проекта
 * @param props.url - URL, можеть быть `string`
 */
export const getAbsoluteUrl: AbsoluteUrlType = ({
  domainConfig,
  projectId,
  url,
}) => {
  const domain = domainGenerator(domainConfig, projectId);

  return `${domain}${url}`;
};

type SwapUrlToProxyDomainType = (props: {
  url: string;
  domainConfig: DomainConfigType;
  projectId: ProjectType['id'];
}) => string;

export const swapUrlToProxyDomain: SwapUrlToProxyDomainType = ({
  domainConfig,
  projectId,
  url,
}) => {
  if (checkProxyDomainInUrl(url)) return url;

  try {
    const parsedUrl = new URL(url);
    const domain = new URL(domainGenerator(domainConfig, projectId));

    parsedUrl.hostname = domain.hostname;

    if (__DEV__) {
      parsedUrl.port = `${PORT}`;
    }

    return parsedUrl.toString();
  } catch {
    return url;
  }
};
