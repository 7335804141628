import { toZonedTime } from 'date-fns-tz';

import { fecha } from './fecha';
import { rupluralize } from './rupluralize';

const formatter = fecha.format;
export const parser = fecha.parse;

/**
 * Преобразование даты в формат 'D MMM YYYY' или 'D MMM'
 * @param pubdate - дата публикации кластера
 */
export const ruStrftime = (pubdate: number) => {
  const date = new Date(pubdate * 1000);
  const format =
    date.getFullYear() === new Date().getFullYear()
      ? 'withoutCurrentYear'
      : 'rusFullDateGenitive';

  return formatter(date, format);
};

type DatesinceType = {
  pubdate: number;
  date?: Date;
  isShort?: boolean;
};

/**
 * Функция для отображения даты и времени с определенного момента
 * @param props - пропсы
 * @param props.pubdate - дата публикации кластера
 * @param props.date - дата, от которой начинается отсчёт
 * @param props.isShort - флаг короткой записи - мин вместо минут и ч вместо часов
 */
export const datesince = ({
  pubdate,
  date = new Date(),
  isShort,
}: DatesinceType) => {
  if (!pubdate) return '';
  const interval =
    Math.floor(Math.abs(date.getTime() - pubdate * 1000) / 1000) / 60;
  const getStrDate = (value: number, period: string) =>
    `${Math.floor(value)} ${period} назад`;

  if (interval < 1) {
    return 'Только что';
  }

  if (interval < 60) {
    const dateStr = isShort
      ? 'мин'
      : rupluralize(Math.floor(interval), ['минуту', 'минуты', 'минут']);

    return getStrDate(interval, dateStr);
  }

  const hoursInterval = interval / 60;

  if (hoursInterval < 24) {
    const dateStr = isShort
      ? 'ч'
      : rupluralize(Math.floor(hoursInterval), ['час', 'часа', 'часов']);

    return getStrDate(hoursInterval, dateStr);
  }

  return ruStrftime(pubdate);
};

/**
 * Преобразование даты в формат '/YYYY/MM/DD/'
 * @param pubdate - дата публикации кластера
 */
export const getDateWithSlash = (pubdate: number) => {
  const date = new Date(pubdate * 1000);

  return formatter(date, 'dateWithSlashSeparator');
};

/**
 * Преобразование даты в формат 'YYYY-MM-DD'
 * @param maxDate - максимальная дата календаря
 */

export const calendarDate = (maxDate: number | string | Date) => {
  const date = new Date(maxDate);

  if (Number.isNaN(date.getTime())) return '';

  return formatter(date, 'calendar');
};

/**
 * Преобразование даты в формат 'DD MMM YYYY
 * @param year - string
 * @param month - string
 * @param day - string
 */
export const createGenetiveDate = (
  year: string,
  month: string,
  day: string,
) => {
  const date = new Date(Number(year), Number(month) - 1, Number(day));

  return ruStrftime(date.getTime() / 1000);
};

type GetShortTimeType = (date: string | number | Date) => string;

/**
 * Получение времени в формате HH:MM
 * @param date - дата
 */
export const getShortTime: GetShortTimeType = (date) =>
  formatter(new Date(date), 'shortTime');

/**
 * Получение времени в формате MM:SS
 * @param time - время в миллисекундах.
 */
export const timeToDuration = (time: number) =>
  formatter(new Date(time), 'mm:ss');

/**
 * Получение даты в формате DD.MM
 * @param date - строчка с датой.
 */
export const dateToDayMonth = (date: string) => {
  const dateObj = new Date(date);

  if (Number.isNaN(dateObj.getTime())) return '00.00';

  return formatter(new Date(date), 'DD.MM');
};

/**
 * Получение даты в формате MM.YYYY
 * @param date - строчка с датой.
 */
export const dateToMonthYear = (date: string) => {
  const dateObj = new Date(date);

  if (Number.isNaN(dateObj.getTime())) return '00.00';

  return formatter(new Date(date), 'MM.YYYY');
};

/**
 * Получение даты в формате DD.MM.YYYY
 * @param date - строчка с датой.
 */
export const dateToFullYear = (date: string | number | Date) => {
  const dateObj = new Date(date);

  if (Number.isNaN(dateObj.getTime())) return '';

  return formatter(new Date(date), 'DD.MM.YYYY');
};

/**
 * Получение даты со временем в формате YYYY-MM-DDTHH:mm:ss
 * @param date - строка с датой.
 */
export const dateToFullDateWithTime = (date: string | number | Date) => {
  const dateObj = new Date(date);

  if (Number.isNaN(dateObj.getTime())) return '';

  return formatter(dateObj, 'YYYY-MM-DDTHH:mm:ss');
};

/**
 * Создает дату кластера в формате "дд %месяц% (год опционально), чч:мм" по МСК
 * @param fullDate - дата, пример: '2022-10-19T00:07:15+03:00'
 */
export const getClusterDate = (fullDate: string) => {
  const date = new Date(fullDate);
  const moscowDate = toZonedTime(date, 'Europe/Moscow');

  // если год совпадает с текущим, год не выводим
  const format =
    moscowDate.getFullYear() === new Date().getFullYear()
      ? 'clusterDataWithoutYear'
      : 'clusterFullData';

  return formatter(moscowDate, format);
};

/**
 * Создает дату в формате "дд %месяц%"
 * @param fullDate - дата, пример: '2022-10-19T00:07:15+03:00'.
 */
export const getDayAndMonth = (fullDate: string) => {
  const date = new Date(fullDate);

  return formatter(date, 'dayAndMonth');
};
