import { DEVICE_TAG } from '../constants';

type ParamType =
  | { [DEVICE_TAG.DESKTOP]: [string] }
  | { [DEVICE_TAG.MOBILE]: [string] };

type FilteredParamsType = {
  [x: string]: ParamType;
};

/**
 * Функция-фильтратор входящего объекта параметров, чтобы ТОЧНО был объект, содержащий объекты.
 * @param params - входищий объект с параметрами.
 * @param isMobile - флаг мобильной версии
 */
const filterParamsObject = (
  params: Record<string, unknown> | undefined,
  isMobile?: boolean,
): FilteredParamsType => {
  const checkIsObject = (obj: any): obj is ParamType =>
    !(!obj || typeof obj !== 'object' || Array.isArray(obj));

  if (!checkIsObject(params)) return {};

  return Object.entries(params).reduce((acc, [name, value]) => {
    if (!checkIsObject(value)) {
      acc[name] = isMobile
        ? { [DEVICE_TAG.MOBILE]: [String(value)] }
        : { [DEVICE_TAG.DESKTOP]: [String(value)] };

      return acc;
    }

    acc[name] = value;

    return acc;
  }, {} as FilteredParamsType);
};

type AddParamsType = (props: {
  name: string;
  selector: (state: AppState) => string | undefined;
  isMobile: boolean;
  paramShouldBeIgnored?: boolean;
  mapToYMValue?: (splitValue: string) => string | undefined;
}) => YaMetricaParamsType;

type YaMetricaParamsType = {
  addParams: AddParamsType;
  getParams: () => FilteredParamsType;
  _params: FilteredParamsType;
};

type ParamsModifierType = (props: {
  params?: Record<string, unknown>;
  isMobile?: boolean;
  state: AppState;
}) => YaMetricaParamsType;

/**
 * Функция-модфикатор объекта парамтеров, отправляемых в
 * @param props - пропсы
 * @param props.params - дополнительный объект с параметрами, отправляемые в яндекс метрику;
 * @param props.state - объект стейта.
 * @param props.isMobile - флаг мобильной версии приложения
 */
export const paramsModifier: ParamsModifierType = ({
  params,
  state,
  isMobile,
}) => ({
  _params: filterParamsObject(params, isMobile),

  /**
   * Метод, позволяющий насытить итоговый объект параметрами.
   * @param props - пропсы
   * @param props.name - название параметра, отправляемого в ЯМетрику;
   * @param props.selector - объект селектора;
   * @param props.isMobile - флаг, что это мобильное устройство;
   * @param props.paramShouldBeIgnored - флаг, что данный параметр надо игнорировать (для управления извне);
   * @param props.mapToYMValue - мапка, приводящая значения сплита к значениям, ожидаемым в Яндекс Метрике.
   *  Если значения не будет в этой мапке, то оно не будет отправляться.
   */
  addParams({
    name,
    selector,
    isMobile,
    paramShouldBeIgnored = false,
    mapToYMValue,
  }) {
    if (!name) return this;
    if (paramShouldBeIgnored) return this;

    const split = selector(state);

    if (!split && split !== '') return this;
    const mappedSplitValue = mapToYMValue ? mapToYMValue(split) : split;
    // Проверка, что значение сплита есть в переданной мапке
    if (!mappedSplitValue) return this;

    this._params[name] = {
      [isMobile ? DEVICE_TAG.MOBILE : DEVICE_TAG.DESKTOP]: [mappedSplitValue],
    } as ParamType;

    return this;
  },

  /**
   * Метод, возвращающий готовые параметры.
   */
  getParams() {
    return this._params;
  },
});
