import { getPublisherScope } from 'common/hooks/usePublishedAdData';
import { selectProjectAlias, selectRuid } from 'common/redux/runtime/selectors';
import { COUNTERS } from 'config/common/counters/constants';

import { top100Wrapper } from '../top100Wrapper';
import { getTop100Dataset, getTop100Splits } from '../utils';

/**
 * Это - клиентский файл.
 * Он может использоваться как для клиента, так и для сервера.
 */

/**
 * Функция инициализации топ100 на клиенте.
 * @param state - стейт приложения.
 */
export const initClientTop100 = (state: AppState) => {
  const projectAlias = selectProjectAlias(state);
  const ruid = selectRuid(state);

  top100Wrapper.init({
    projectIds: [
      COUNTERS[projectAlias].top100,
      COUNTERS.sberPortal.top100,
      COUNTERS.sberStats.top100,
    ],
    pub_id: ruid,
    pub_scope: getPublisherScope(),
    attributes_dataset: getTop100Dataset(),
    splits: getTop100Splits(state),
  });
};
