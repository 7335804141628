/**
 * Функция проверки корректного url для работы
 * с конструктором new URL()
 * https://developer.mozilla.org/ru/docs/Web/API/URL/URL
 * @param url - строка для проверки
 */
export const checkCorrectUrl = (url: string) => {
  try {
    const objectUrl = new URL(url);

    return Boolean(objectUrl);
  } catch {
    return false;
  }
};
