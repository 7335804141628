import { HUMAN_CENTERED_STRATEGY_SPLIT_VALUES } from 'config/common/splits/typings';

// NEWS-11896, 12028
export const mediaTop100HumanCenteredStrategyDesk = {
  [HUMAN_CENTERED_STRATEGY_SPLIT_VALUES.default]:
    'humanCenteredStrategy_web_default',
  [HUMAN_CENTERED_STRATEGY_SPLIT_VALUES.off]:
    'humanCenteredStrategy_web_redesign_off',
  [HUMAN_CENTERED_STRATEGY_SPLIT_VALUES.on]:
    'humanCenteredStrategy_web_redesign_1',
  [HUMAN_CENTERED_STRATEGY_SPLIT_VALUES.on_4]:
    'humanCenteredStrategy_web_redesign_4',
  [HUMAN_CENTERED_STRATEGY_SPLIT_VALUES.on_1_4]:
    'humanCenteredStrategy_web_redesign_1_4',
  [HUMAN_CENTERED_STRATEGY_SPLIT_VALUES.fullscreen_off]:
    'humanCenteredStrategy_web_fullscreen_off',
  [HUMAN_CENTERED_STRATEGY_SPLIT_VALUES.fullscreen_on]:
    'humanCenteredStrategy_web_fullscreen_on',
};

// NEWS-11896, 12028
export const mediaTop100HumanCenteredStrategyMob = {
  [HUMAN_CENTERED_STRATEGY_SPLIT_VALUES.default]:
    'humanCenteredStrategy_mob_default',
  [HUMAN_CENTERED_STRATEGY_SPLIT_VALUES.off]:
    'humanCenteredStrategy_mob_redesign_off',
  [HUMAN_CENTERED_STRATEGY_SPLIT_VALUES.on]:
    'humanCenteredStrategy_mob_redesign_1',
  [HUMAN_CENTERED_STRATEGY_SPLIT_VALUES.on_4]:
    'humanCenteredStrategy_mob_redesign_4',
  [HUMAN_CENTERED_STRATEGY_SPLIT_VALUES.on_1_4]:
    'humanCenteredStrategy_mob_redesign_1_4',
  [HUMAN_CENTERED_STRATEGY_SPLIT_VALUES.fullscreen_off]:
    'humanCenteredStrategy_mob_fullscreen_off',
  [HUMAN_CENTERED_STRATEGY_SPLIT_VALUES.fullscreen_on]:
    'humanCenteredStrategy_mob_fullscreen_on',
};
