import { PAGE_TYPE } from 'config/common/router/typings';

import { liveInternetHit } from '../../liveInternet';
import { TNShit } from '../../tns/client';
import { top100Wrapper } from '../../top100/top100Wrapper';
import { traffgen } from '../../traffgen';
import { yaHit } from '../../yaMetrika';

/**
 * Инициализация сборщиков метрик на клиенте в скрипте.
 * @param projectAlias - alias проекта
 */
export const initScriptMetrics = (projectAlias: ProjectType['alias']) => {
  liveInternetHit();
  TNShit(projectAlias);
};

/**
 * Инициализация пикселя, выносим отдельно в рамках
 * https://jira.rambler-co.ru/browse/NEWS-11040
 */
export const initTraffgen = () => {
  window.addEventListener('load', () => traffgen());
};

const PAGES_WITH_INCREMENTING_COUNTER = [
  PAGE_TYPE.autotag,
  PAGE_TYPE.date,
  PAGE_TYPE.cluster,
];

type IncrementType = 'cluster' | 'feed' | 'autotag';

type IncrementCountersPropsType = {
  runtime: Runtime;
  pageName?: PAGE_TYPE;
  type?: IncrementType;
};

/**
 * Инкрементация счетчиков
 * @param props - пропсы
 * @param props.runtime - общая информация для приложения
 * @param props.type - тип страницы для инкрементации
 * @param props.pageName - имя страницы
 */
export const incrementCounters = ({
  runtime,
  type = 'cluster',
  pageName,
}: IncrementCountersPropsType) => {
  const url = `${window.location.pathname}${window.location.search}${window.location.hash}`;

  /**
   * Инкрементация гугл-аналитики https://jira.rambler-co.ru/browse/NEWS-10376 пока не используем
   */
  // gaHit(url);

  // Инкрементация яндекс-метрики
  yaHit(runtime, url);
  // Инкрементация top100
  top100Wrapper.trackPageview();
  // Увеличение счетчика аудитории
  TNShit(runtime.project.alias);

  if (
    type === 'cluster' ||
    PAGES_WITH_INCREMENTING_COUNTER.includes(pageName!)
  ) {
    liveInternetHit();
  }
};
